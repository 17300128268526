import { htmlSafe } from "@ember/template";
import { registerRawHelper } from "discourse-common/lib/helpers";
registerRawHelper("topic-link", topicLink);
export default function topicLink(topic) {
  let args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const title = topic.get("fancyTitle");
  const url = topic.linked_post_number ? topic.urlForPostNumber(topic.linked_post_number) : topic.get("lastUnreadUrl");
  const classes = ["title"];
  if (args.class) {
    args.class.split(" ").forEach(c => classes.push(c));
  }
  return htmlSafe(`<a href='${url}'
        class='${classes.join(" ")}'
        data-topic-id='${topic.id}'>${title}</a>`);
}